import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import editer from "./editer";

Vue.use(Vuex);

const store = {
  state: {
    ...app.state,
    ...editer.state,
  },
  mutations: {
    ...app.mutations,
    ...editer.mutations,
  },
  getters: {
    ...app.getters,
    ...editer.getters,
  },
  actions: {
    ...app.actions,
    ...editer.actions,
  },
};

export default new Vuex.Store(store);
