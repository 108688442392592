import { getSts } from "@/api/aliOSS.js";
import { Message } from "element-ui";
import OSS from "ali-oss";

export default {
  state: {
    powerList: [],
    oSSSecret: {},
    client: {},
  },
  getters: {
    // 参数列表state指的是state数据
    getPowerList(state) {
      return state.powerList;
    },
    getOSSSecret(state) {
      return state.oSSSecret;
    },
    getClient: (state) => state.client,
  },
  mutations: {
    setPowerList(state, data) {
      state.powerList = data;
    },
    SET_SECRET: (state, data) => {
      state.oSSSecret = data;
    },
    SET_CLIENT: (state, client) => {
      state.client = client;
    },
  },
  actions: {
    setPowerData({ commit, state }, data) {
      commit("setPowerList", data);
    },
    getSecret({ commit }) {
      return new Promise((resolve, reject) => {
        getSts()
          .then((response) => {
            commit("SET_SECRET", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            Message.error("OSS初始化失败，请刷新页面后再试");
            reject(error);
          });
      });
    },
    async getClient({ dispatch, commit }) {
      const { accessKeySecret, securityToken, accessKeyId } = await dispatch(
        "getSecret"
      );

      const client = new OSS({
        region: "oss-cn-chengdu",
        secure: true,
        accessKeyId,
        accessKeySecret,
        stsToken: securityToken,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          const info = await dispatch("getSecret");
          // const info = await getSts();
          return {
            accessKeyId: info.accessKeyId,
            accessKeySecret: info.accessKeySecret,
            stsToken: info.securityToken,
          };
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 300000,
        // 填写Bucket名称。
        bucket: "dapianlaile",
      });
      commit("SET_CLIENT", client);
    },
  },
};
