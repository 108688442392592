import request from "@/utils/request";

//  获取OSS
export function getSts(data) {
  return request({
    url: "misc-web-api/common/file/getSts",
    method: "get",
    data,
  });
}
