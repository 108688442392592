import request from '@/utils/request'

//  获取标签树
export function getTagTree(data) {
  return request({
    url: '/tag-web-api/admin/tag/getTagTree',
    method: 'POST',
    data,
  })
}
// 祝福消息分页
export function getList(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlanSendLog/save',
    method: 'post',
    data: params,
  })
}
// 电话拨打记录

export function getPhoneList(params) {
  return request({
    url: '/marketing-web-api/admin/callcenter/getList',
    method: 'post',
    data: params,
  })
}

export function getChannelTree(params) {
  return request({
    url: '/misc-web-api/admin/channel/getChannelTree',
    method: 'post',
    data: params,
  })
}

export function getChannelByType(params) {
  return request({
    url: '/misc-web-api/admin/channel/getChannelByType',
    method: 'post',
    data: params,
  })
}
export function pageCustomer(params) {
  return request({
    url: '/customer-web-api/admin/customer/pageCustomer',
    method: 'post',
    data: params,
  })
}
export function messageTemplateCategory(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplateCategory/getList',
    method: 'post',
    data: params,
  })
}
export function chooseMessTemplate(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplate/chooseMessTemplate',
    method: 'post',
    data: params,
  })
}
export function saveBirthday(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlan/saveBirthday',
    method: 'post',
    data: params,
  })
}
export function addMessagePlan(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlan/save',
    method: 'post',
    data: params,
  })
}
export function queryChildsById(params) {
  return request({
    url: '/misc-web-api/common/dictionary/queryChildsById',
    method: 'post',
    data: params,
  })
}
export function getEnbleChannelTree(params) {
  return request({
    url: '/misc-web-api/admin/channel/getEnbleChannelTree',
    method: 'post',
    data: params,
  })
}
export function getChannelPage(params) {
  return request({
    url: '/misc-web-api/admin/channel/getChannelPage',
    method: 'post',
    data: params,
  })
}
export function businessCard(params) {
  return request({
    url: '/marketing-web-api/admin/businessCard/getList',
    method: 'post',
    data: params,
  })
}
export function businessCardsave(params) {
  return request({
    url: '/marketing-web-api/admin/businessCard/save',
    method: 'post',
    data: params,
  })
}
export function departmentTree(params) {
  return request({
    url: '/user-web-api/admin/department/tree',
    method: 'post',
    data: params,
  })
}
export function platformPageList(params) {
  return request({
    url: '/user-web-api/admin/user/info/platform/pageList',
    method: 'post',
    data: params,
  })
}
export function getPostPage(params) {
  return request({
    url: '/user-web-api/admin/post/getPostPage',
    method: 'post',
    data: params,
  })
}
export function getWishPlan(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlan/getWishPlan',
    method: 'post',
    data: params,
  })
}
export function businessCardDel(params) {
  return request({
    url: '/marketing-web-api/admin/businessCard/delete',
    method: 'post',
    data: params,
  })
}
export function messagePlanGetList(params) {
  const data = {
    url: '/marketing-web-api/admin/messagePlan/getList',
    method: 'post',
    data: params,
  }
  if (Number(params.data.isExport) === 1) {
    data.responseType = 'blob'
  }
  return request({
    ...data,
  })
}
export function callcenterExportList(params) {
  return request({
    url: '/marketing-web-api/admin/callcenter/exportList',
    method: 'post',
    data: params,
    responseType: 'blob',
  })
}
export function messagePlanDetail(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlan/detail',
    method: 'post',
    data: params,
  })
}
export function cancelSend(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlan/cancelSend',
    method: 'post',
    data: params,
  })
}
export function saleScriptGetList(params) {
  return request({
    url: '/marketing-web-api/admin/saleScript/getList',
    method: 'post',
    data: params,
  })
}

export function questionAnswerGetList(params) {
  return request({
    url: '/marketing-web-api/admin/questionAnswer/getList',
    method: 'post',
    data: params,
  })
}
export function messagePlanSendLog(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlanSendLog/save',
    method: 'post',
    data: params,
  })
}
export function messageTemplateGetList(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplate/getList',
    method: 'post',
    data: params,
  })
}
export function detailSendMessById(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlanSendLog/detailSendMessById',
    method: 'post',
    data: params,
  })
}
export function saveTemplate(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplate/saveTemplate',
    method: 'post',
    data: params,
  })
}
export function detailByIds(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplate/detailByIds',
    method: 'post',
    data: params,
  })
}
export function saleGetList(params) {
  return request({
    url: '/marketing-web-api/admin/sale/getList',
    method: 'post',
    data: params,
  })
}

export function saleSave(params) {
  return request({
    url: '/marketing-web-api/admin/sale/save',
    method: 'post',
    data: params,
  })
}
export function saleDelete(params) {
  return request({
    url: '/marketing-web-api/admin/sale/delete',
    method: 'post',
    data: params,
  })
}
export function selectByExample(params) {
  return request({
    url: '/marketing-web-api/admin/sale/selectByExample',
    method: 'post',
    data: params,
  })
}
export function saleScriptDelete(params) {
  return request({
    url: '/marketing-web-api/admin/saleScript/delete',
    method: 'post',
    data: params,
  })
}
export function saleScriptSave(params) {
  return request({
    url: '/marketing-web-api/admin/saleScript/save',
    method: 'post',
    data: params,
  })
}
export function questionAnswerCateList(params) {
  return request({
    url: '/marketing-web-api/admin/questionAnswerCategory/getList',
    method: 'post',
    data: params,
  })
}

export function questionAnswerCatedelete(params) {
  return request({
    url: '/marketing-web-api/admin/questionAnswerCategory/delete',
    method: 'post',
    data: params,
  })
}
export function questionAnswerCateSave(params) {
  return request({
    url: '/marketing-web-api/admin/questionAnswerCategory/save',
    method: 'post',
    data: params,
  })
}
export function questionAnswerSave(params) {
  return request({
    url: '/marketing-web-api/admin/questionAnswer/save',
    method: 'post',
    data: params,
  })
}
export function questionAnswerDelete(params) {
  return request({
    url: '/marketing-web-api/admin/questionAnswer/delete',
    method: 'post',
    data: params,
  })
}

export function shootTopicGetList(params) {
  return request({
    url: '/marketing-web-api/admin/shootTopic/getList',
    method: 'post',
    data: params,
  })
}

export function shootTopicDelete(params) {
  return request({
    url: '/marketing-web-api/admin/shootTopic/delete',
    method: 'post',
    data: params,
  })
}
export function shootTopicDetail(params) {
  return request({
    url: '/marketing-web-api/admin/shootTopic/detail',
    method: 'post',
    data: params,
  })
}
export function shootTopicCommentGetList(params) {
  return request({
    url: '/marketing-web-api/admin/shootTopicComment/getList',
    method: 'post',
    data: params,
  })
}
export function shootTopicCommentDelete(params) {
  return request({
    url: '/marketing-web-api/admin/shootTopicComment/delete',
    method: 'post',
    data: params,
  })
}
export function messageTemplateCategoryDel(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplateCategory/delete',
    method: 'post',
    data: params,
  })
}
export function messageTemplateCategorySave(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplateCategory/saveMessCategory',
    method: 'post',
    data: params,
  })
}
export function messageTemplatebathchIdse(params) {
  return request({
    url: '/marketing-web-api/admin/messageTemplate/bathchIds',
    method: 'post',
    data: params,
  })
}
export function messagePlanSendLogGetList(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlanSendLog/getList',
    method: 'post',
    data: params,
  })
}
export function messagePlanSendLogremove(params) {
  return request({
    url: '/marketing-web-api/admin/messagePlanSendLog/remove',
    method: 'post',
    data: params,
  })
}
export function getChanceCountByStatus(data) {
  return request({
    url: 'chance-web-api/admin/chance/getChanceCountByStatus',
    method: 'post',
    data,
  })
}
export function getChannelTypeByLevelOne(data) {
  return request({
    url: 'misc-web-api/admin/channel/getChannelTypeByLevelOne',
    method: 'get',
    data,
  })
}
export function getRegionList(data) {
  return request({
    url: 'misc-web-api/common/region/regionList',
    method: 'post',
    data,
  })
}
export function provinceListByRegionId(data) {
  return request({
    url: 'misc-web-api/common/region/provinceListByRegionId',
    method: 'post',
    data,
  })
}
export function districtQueryById(data) {
  return request({
    url: 'misc-web-api/common/district/queryById',
    method: 'post',
    data,
  })
}
export function chooseMessTemplateByTouch(data) {
  return request({
    url: 'marketing-web-api/admin/messageTemplate/chooseMessTemplateByTouch',
    method: 'post',
    data,
  })
}
export function chooseMessTemplateByBirthday(data) {
  return request({
    url: 'marketing-web-api/admin/messageTemplate/chooseMessTemplateByBirthday',
    method: 'post',
    data,
  })
}
export function getCustomerNum(data) {
  return request({
    url: 'marketing-web-api/admin/messagePlan/getCustomerNum',
    method: 'post',
    data,
  })
}
export function findListByUserId(data) {
  return request({
    url: '/user-web-api/admin/permission/findListByUserId',
    method: 'POST',
    data,
  })
}
export function getSmsLink(data) {
  return request({
    url: '/misc-web-api/wechat/getMiniWeChatUrlLinkSMS',
    method: 'POST',
    data,
  })
}
export function messageLinkAddOrEdit(data) {
  return request({
    url: '/marketing-web-api/admin/messageLink/save',
    method: 'POST',
    data,
  })
}
export function getMessageLinkPageList(data) {
  return request({
    url: '/marketing-web-api/admin/messageLink/pageList',
    method: 'POST',
    data,
  })
}
export function deleteMessage(data) {
  return request({
    url: '/marketing-web-api/admin/messageLink/deleteMessage',
    method: 'POST',
    data,
  })
}
export function messageLinkDetail(data) {
  return request({
    url: '/marketing-web-api/admin/messageLink/detail',
    method: 'POST',
    data,
  })
}
export function getMiniAppLink(data) {
  return request({
    url: '/misc-web-api/common/dictionary/queryChildsById',
    method: 'POST',
    data,
  })
}
export function findShareCodeListByUserId(data) {
  return request({
    url: '/user-web-api/admin/share/code/findListByUserId',
    method: 'POST',
    data,
  })
}

export function generateShareCode(data) {
  return request({
    url: '/user-web-api/admin/share/code/generateShareCode',
    method: 'POST',
    data,
  })
}

export function getUssGroupList(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/getUssGroupList',
    method: 'POST',
    data,
  })
}

// 获取呼叫平台
export function getCallApp(data) {
  return request({
    url: 'user-web-api/admin/callcenter/getCallApp',
    method: 'POST',
    data,
  })
}

//  根据条件查询列表
export function getListByQuery(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/skill/groups/getListByQuery',
    method: 'POST',
    data,
  })
}
