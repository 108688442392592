import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '../layouts/BaseLayout.vue'
import store from '@/store'
import { findListByUserId } from '@/api'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: BaseLayout,
    redirect: '/guidePage',
    children: [
      {
        path: '/touch',
        name: 'touch',
        component: () => import('../views/touch/index.vue'),
      },
      // {
      //   path: "/msgPush",
      //   name: "msgPush",
      //   component: () =>
      //     import("../views/msgPush/index.vue"),
      // },
      // {
      //   path: "/msgSend",
      //   name: "msgSend",
      //   component: () =>
      //     import("../views/msgSend/index.vue"),
      // },
      {
        path: '/callRecord',
        name: 'callRecord',
        component: () => import('../views/callRecord/index.vue'),
      },
      {
        path: '/skillGroups',
        name: 'skillGroups',
        component: () => import('../views/skillGroups/index.vue'),
      },
      {
        path: '/smartSpeech',
        name: 'smartSpeech',
        component: () => import('../views/smartSpeech/index.vue'),
      },
      {
        path: '/q_a_Library',
        name: 'q_a_Library',
        component: () => import('../views/q_a_Library/index.vue'),
      },
      {
        path: '/smartCard',
        name: 'smartCard',
        component: () => import('../views/smartCard/index.vue'),
      },
      {
        path: '/msgTemplate',
        name: 'msgTemplate',
        component: () => import('../views/msgTemplate/index.vue'),
      },
      {
        path: '/autoBlessing',
        name: 'autoBlessing',
        component: () => import('../views/autoBlessing/index.vue'),
      },
      {
        path: '/msgPushPlan',
        name: 'msgPushPlan',
        component: () => import('../views/msgPushPlan/index.vue'),
      },
      {
        path: '/talkList',
        name: 'talkList',
        component: () => import('../views/talkList/index.vue'),
      },
      {
        path: '/guidePage',
        name: 'guidePage',
        component: () => import('../views/guidePage/index.vue'),
      },
      {
        path: '/cardVoucher',
        name: 'cardVoucher',
        component: () => import('../views/sourceMaterial/cardVoucher/index.vue'),
      },
      {
        path: '/cardRecord',
        name: 'cardRecord',
        component: () => import('../views/sourceMaterial/cardRecord/index.vue'),
      },
      {
        path: '/cardVoucher/issuingRecord',
        name: 'issuingRecord',
        component: () => import('@/views/sourceMaterial/cardVoucher/issuingRecord/index.vue'),
      },
      {
        path: '/promotion/statistics',
        name: 'promotionStatistics',
        component: () => import('@/views/promotion/promotionStatistics/index.vue'),
      },
      {
        path: '/smsPromotion',
        name: 'smsPromotion',
        component: () => import('@/views/smsPromotion/index.vue'),
      },
      {
        path: '/integralSetup',
        name: 'integralSetup',
        component: () => import('@/views/integralSetup/index.vue'),
      },
    ],
  },
  {
    path: '/special',
    name: 'special',
    component: BaseLayout,
    redirect: '/special/specialList',
    children: [
      {
        path: '/special/specialList',
        name: 'specialList',
        component: () => import('@/views/special/specialList/index.vue'),
      },
      {
        path: '/special/specialConfig',
        name: 'specialConfig',
        component: () => import('@/views/special/specialConfig/index.vue'),
      },
    ],
  },
  {
    path: '/activity',
    name: 'activity',
    mate: {
      title: '活动管理',
    },
    component: BaseLayout,
    redirect: '/activity/prizeList',
    children: [
      {
        path: '/activity/prizeList',
        name: 'prizeList',
        mate: {
          title: '礼品管理',
        },
        component: () => import('@/views/activity/prizeList/index.vue'),
      },

      {
        path: '/activity/fullGift/list',
        name: 'fullGiftList',
        meta: { title: '满赠列表' },
        component: () => import('@/views/activity/fullGift/index.vue'),
      },
      {
        path: '/activity/fullGift/detail',
        name: 'fullGiftDetail',
        meta: { title: '满赠详情' },
        component: () => import('@/views/activity/fullGift/edit.vue'),
      },
      {
        path: '/activity/giftReleaseHistory',
        name: 'giftReleaseHistory',
        meta: { title: '礼品发放记录' },
        component: () => import('@/views/activity/giftReleaseHistory/index.vue'),
      },
    ],
  },
  {
    path: '/blindBox',
    name: 'blindBox',
    mate: {
      title: '盲盒管理',
    },
    component: BaseLayout,
    redirect: '/blindBox/blindBoxList',
    children: [
      {
        path: '/blindBox/addAndEditBlindBox',
        name: 'addAndEditBlindBox',
        mate: {
          title: '添加/编辑盲盒',
        },
        component: () => import('@/views/blindBox/blindBoxList/addAndEditBlindBox.vue'),
      },
      {
        path: '/blindBox/blindBoxList',
        name: 'blindBoxList',
        mate: {
          title: '盲盒列表',
        },
        component: () => import('@/views/blindBox/blindBoxList/index.vue'),
      },
      {
        path: '/blindBox/awardInfo',
        name: 'awardInfo',
        mate: {
          title: '领取记录',
        },
        component: () => import('@/views/blindBox/awardInfo/index.vue'),
      },
      {
        path: '/blindBox/ruleConfig',
        name: 'ruleConfig',
        mate: {
          title: '盲盒规则',
        },
        component: () => import('@/views/blindBox/ruleConfig/index.vue'),
      },
    ],
  },

  {
    path: '/friendCircle',
    name: 'friendCircle',
    component: BaseLayout,
    children: [
      {
        path: 'advertisement',
        name: 'advertisement',
        component: () => import('@/views/friendCircle/advertisement.vue'),
      },
      {
        path: 'momentsList',
        name: 'momentsList',
        component: () => import('@/views/friendCircle/momentsList/index.vue'),
      },
      {
        path: 'momentsDetail',
        name: 'momentsDetail',
        component: () => import('@/views/friendCircle/momentsDetail/index.vue'),
      },
      {
        path: 'momentsCategoryList',
        name: 'momentsCategoryList',
        component: () => import('@/views/friendCircle/momentsCategoryList/index.vue'),
      },
      {
        path: 'topicCategoryList',
        name: 'topicCategoryList',
        component: () => import('@/views/friendCircle/topicCategoryList/index.vue'),
      },
      {
        path: 'topicList',
        name: 'topicList',
        component: () => import('@/views/friendCircle/topicList/index.vue'),
      },
      {
        path: 'topicList/add',
        name: 'topicListAdd',
        component: () => import('@/views/friendCircle/topicList/addOrEdit/index.vue'),
      },
      {
        path: 'topicList/detail',
        name: 'topicListDetail',
        component: () => import('@/views/friendCircle/topicList/topicDetail/index.vue'),
      },
    ],
  },
  {
    path: '/integralManage',
    name: 'integralManage',
    component: BaseLayout,
    redirect: '/integralManage/integralDetail',
    children: [
      {
        path: '/integralManage/integralDetail',
        name: 'integralDetail',
        component: () => import('@/views/integralManage/integralDetail/index.vue'),
      },
      {
        path: '/integralManage/integralRule',
        name: 'integralRule',
        component: () => import('@/views/integralManage/integralRule/index.vue'),
      },
      {
        path: 'integralTypeList',
        name: 'integralTypeList',
        component: () => import('@/views/integralManage/integralTypeList/index.vue'),
      },
    ],
  },
  {
    path: '/questionnaireManage',
    name: 'questionnaireManage',
    component: BaseLayout,
    children: [
      {
        path: '/questionnaireManage/index',
        name: 'questionnaireManageIndex',
        component: () => import('@/views/questionnaireManage/index.vue'),
      },
      {
        path: '/questionnaireManage/addQuestionnaire',
        name: 'addQuestionnaire',
        component: () => import('@/views/questionnaireManage/addQuestionnaire/index.vue'),
      },
      {
        path: '/questionnaireManage/questionnaireDetail',
        name: 'questionnaireDetail',
        component: () => import('@/views/questionnaireManage/questionnaireDetail/index.vue'),
      },
      {
        path: '/questionnaireManage/addOrEditIssue',
        name: 'addOrEditIssue',
        component: () => import('@/views/questionnaireManage/addOrEditIssue/index.vue'),
      },
      {
        path: '/questionnaireManage/addOrEditTemplate',
        name: 'addOrEditTemplate',
        component: () => import('@/views/questionnaireManage/addOrEditTemplate/index.vue'),
      },
      {
        path: '/questionnaireManage/templateDetail',
        name: 'templateDetail',
        component: () => import('@/views/questionnaireManage/templateDetail/index.vue'),
      },
    ],
  },
  {
    path: '/interest',
    name: 'interest',
    component: BaseLayout,
    redirect: '/interest/interestList',
    children: [
      {
        path: '/interest/interest-classify',
        name: 'interestClassify',
        component: () => import('@/views/interest/interest-classify/index.vue'),
      },
      {
        path: '/interest/interestList',
        name: 'interestList',
        component: () => import('@/views/interest/interest-list/index.vue'),
      },
      {
        path: '/interest/interestDetail',
        name: 'interestDetail',
        component: () => import('@/views/interest/interest-detail/index.vue'),
      },
    ],
  },
  {
    path: '/vipLevel',
    component: BaseLayout,
    redirect: '/vipLevel/list',
    children: [
      {
        path: 'list',
        name: 'vipLevelList',
        component: () => import('@/views/vipLevel/index.vue'),
      },
      {
        path: 'rules',
        name: 'vipRules',
        component: () => import('@/views/vipLevel/vipRules.vue'),
      },
      {
        path: 'description',
        name: 'vipDescription',
        component: () => import('@/views/vipLevel/vipDescription.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  // 为微应用路由加上前缀，如果不加微应用路由切换会有问题
  // eslint-disable-next-line no-underscore-dangle
  base: window.__POWERED_BY_QIANKUN__ ? '/index/market' : process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  const data = store.getters.getPowerList
  if (data.length === 0) {
    try {
      findListByUserId({
        data: {
          nodeType: 2,
          nodePort: 1,
        },
      }).then((res) => {
        store.dispatch('setPowerData', res.data)

        next()
      })
    } catch {
      next()
    }
  } else {
    next()
  }
})
export default router
