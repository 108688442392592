/**
 * Date: 12/7/20
 */

import axios from "axios";
import { getToken, setToken } from "@/utils/auth";
import { Message } from "element-ui";
import router from "@/router";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    let token = getToken();
    if (process.env.NODE_ENV === "development") {
      token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgF/QbxYmiBxQvTGNxE0UtA4A/WiqVisztbdAlSP9bYHF9JjhZZiMyosLuhjwMAUdYzXMad2jNI6wVj7hxXdCRwEiW91eD95TGD8ZpCHkRXjqXvhlEiks6fOVEMb8geivwHuNJbmy2B7x7TkQBLB3xRBd3zaYI+wzg9Wr5Db1L9HeCMg1/jRhVrog0p5emyr3z6KhS2wMwwQnBLkJ3UIOWqgmRxPOCAq2ZrR+rbYh/T371etEfap/YqPlRyeYgTJBbPJJOI4OC09XrF/3rNu8BkptmZoknjrHx65lL/K7BYzS.cd9a93f064b39896fa5e32b754ab11e26c4e1099";
      setToken(token);
    }
    if (token) {
      config.headers.accessToken = token;
    }

    // 1-大片来了，2-JPG
    const bizLineId = Number(router.currentRoute.query.bizLineId);
    if (
      config.data &&
      config.data.data &&
      typeof config.data.data === "object" &&
      bizLineId
    ) {
      config.data.data.bizLineId = bizLineId;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    console.error(error); // for debug
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data || {};
    if (Object.prototype.toString.call(res) !== "[object Object]") {
      return res;
    }
    if (res.success || res.size) {
      return res;
    } else {
      Message({
        message: res.description,
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(res.description || "error");
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
