import './public-path'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.less'
import './assets/css/details.less'

import VueClipboard from 'vue-clipboard2'

import './plugins/ElementUi.js'

import businesscomponent from 'business-component-next'
import 'business-component-next/lib/style.css'

// process.env.VUE_APP_BASE_API指的是env中配置的请求域名
Vue.use(businesscomponent, {
  url: process.env.VUE_APP_BASE_URL,
  isUseTestToken: false,
})

// 滚动加载更多
Vue.directive('loadMore', {
  bind(el, binding) {
    // 获取element，定义scroll
    const selectDom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
    selectDom.addEventListener('scroll', function () {
      const height = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (height) {
        binding.value()
      }
    })
  },
})

Vue.config.productionTip = false
Vue.use(VueClipboard)

let instance = null

function render(props = {}) {
  // container 是 qiankun 包装过的微应用容器
  const { container } = props

  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

!window.__POWERED_BY_QIANKUN__ && render()
window.unmount = () => unmount()

export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}

export async function mount(props) {
  console.log('[vue] props from main framework')
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}
