export default {
  state: {
    components: [],
    temporary: {},
    selectComponent: {},
    selectIndex: 0,
  },
  getters: {
    getComponents(state) {
      return state.components;
    },
    getTemporary(state) {
      return state.temporary;
    },
    getSelectComponent(state) {
      return state.selectComponent;
    },
    getSelectIndex(state) {
      return state.selectIndex;
    },
  },
  mutations: {
    SET_COMPONENTS(state, data) {
      state.components = data;
    },
    SET_SELECT_COMPONENTS(state, data) {
      state.selectComponent = data.item;
      state.selectIndex = data.index;
    },
    SET_TEMPORARY(state, data) {
      state.temporary = data;
    },
  },
};
